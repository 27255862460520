/* CSS-Stil für den Footer */
.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 0; /* Platzierung des Inhalts anpassen */
    text-align: center; /* Textausrichtung anpassen */
  }

  ul {
    list-style-type: none;
  }

  ul > li{
    display: inline-block;
    margin: 0 10px;
  }